const Config = {
    siteTitle: "Lucid Club App", // Site title.
    siteTitleShort: "Lucid Club App", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    siteTitleAlt: "Lucid Club App", // Alternative site title for SEO.
    siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
    siteUrl: "http://localhost:2017", // Domain of your website without pathPrefix.
    pathPrefix: "/lco",
    siteImagePathUrl: "./t1/assets/images/lucid-logo.png",

    // // Live -- Start
    // baseClubUrl: "http://demo.lucidits.com:8201/LUCIDPOSIntegrationAPI/V1/",
    // Live -- End


    baseClubUrl: "https://clubadmin.lucidpromis.com/LucidClubAPIbeta/V1/",
    imgBaseURL: "https://clubadmin.lucidpromis.com/ClubImgDir/",
    // baseClubUrl: "http://localhost:4321/V1/",
    // imgBaseURL: "http://apps.lucidits.com/ClubImgDir/",
    //token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJMVUNJREFQSUtFWSI6Im9sZFB1RmZZM0dyaERuSFNZSzRrUFFLVXNXS3ptUGtBNHU1T045eFdRODR0NDVtdjFuTmtCTW5QdThMQWF2dlQiLCJVU0VSSUQiOiI5MkY5MTdLaHdDYnZpWW9vQmZOK3hBPT0iLCJpc3MiOiJXV1cuTFVDSURQUk9NSVMuQ09NIiwiYXVkIjoiV1dXLkxVQ0lEUFJPTUlTLkNPTSJ9.TFDJcDpfJEDdWbYw_zmXaJ2j3B08Qz70tFZiR0zq_-E",

    razorpayRedirectUrl: "https://api.razorpay.com/v1/checkout/embedded",
    urlAliveDurationMinutes: 15, // 15 Minitues    
    applicationCode: "CL-APP",
    cmsUserName: "lucid@cms",
    cmsPassword: "admin@cms[lucid]",
    linkUserName: "lucid@WebsiteShorturl",
    linkPassword: "web@Redirect[lucid]",
    secretkey: '$LuC!DCLB@)!^$',
    minMsgTimeout: 800,
    maxMsgTimeout: 1500,
    averageMsgTimeout: 1200,
    verificationTimeout: 4000,
    bannerImageType: {
        Normal: 1,
        Logo: 2,
        Slider: 3,
        BackGroundImage: 4
    },
    pageIds: {
        home: 1,
        menu: 2
    },
    pageType: {
        normal: 1,
        home: 2,
        contact: 3,
        gallery: 4,
        listView: 5,
        member: 6
    },
    pageSession: {
        Home: 1
    },
    paymentVendor: {
        hdfcCCAvenue: 1,
        razorPay: 2
    }
};

module.exports = Config;