import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../component/loadinganimation';
import { withAlert } from 'react-alert' // HOC to show the alert message
import EConfig from '../../data/enumconfig';
let token = '';
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            loading: false,
            fields: {},
            passwordShown: false,
        }
    }

    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }
        token = sHelpers.secureStorage.getItem('club.token');
        sHelpers.removeStoredData();
        sHelpers.mTop();
        //  this.setState({ loading: true });
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        console.log(errorInfo);
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }
    handleLoginClick(e) {
        e.preventDefault();
        this.setState({ loading: true });
        sHelpers.mTop();

        setTimeout(() => {

            let fields = this.state.fields;
            //Mobile No
            if (!fields["mobileno"]) {
                this.props.alert.error("Mobile number cannot be empty");
                document.getElementById('input-mobile-number').focus();
                this.setState({ loading: false });
                return false;
            }
            else if (!fields["password"]) {
                this.props.alert.error("Password cannot be empty");
                document.getElementById('input-password').focus();
                this.setState({ loading: false });
                return false;
            }

            let postObj = {
                Password: this.state.fields["password"],
                AppDeviceId: "",
                TokenId: "",
                Phone: this.state.fields["mobileno"],
                OSVersion: "",
                CrossPlatform: EConfig.crossPlatform.Web
            }
            //  console.log(postObj);


            // Axios.post(Config.baseClubUrl + 'SaveClubMemberDetails', {
            fetch(Config.baseClubUrl + 'ClubMemberLogin', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    //console.log(response);
                    if (response.errorCode === 0) {
                        this.props.alert.success(response.message);
                        sHelpers.secureStorage.setItem('club.mobile.no', response.phone);
                        sHelpers.secureStorage.setItem('club.membership.no', response.membershipNo);
                        sHelpers.secureStorage.setItem('club.membership.id', response.membershipId);
                        sHelpers.secureStorage.setItem('club.displayname', response.displayName);
                        sHelpers.secureStorage.setItem('club.username', response.userName);
                        sHelpers.secureStorage.setItem('club.user.login', true);
                        // set Loggedin date
                        var today = new Date(), date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
                        sHelpers.secureStorage.setItem('club.user.loggedin.date', date);
                        // console.log(sHelpers.secureStorage.getItem('clb.mbno'));
                        // console.log(sHelpers.secureStorage.key('3b3aca62fb56bba2ee7abd3fe7229560517ccce259ae332855510f6cbe4d3f7e'));
                        // console.log(sHelpers.secureStorage.length);                     
                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/dashboard');
                        }, Config.averageMsgTimeout);
                    }
                    else if (response.errorCode === 2) { // redirect to register page
                        this.props.alert.error(response.message);
                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/register', {
                                infoMsg: response.message
                            });
                        }, Config.maxMsgTimeout);
                    }
                    else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.message);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: false });
                    this.props.alert.error(err);
                });
        }, Config.minMsgTimeout);
    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    gotoForgotPassword() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/forgotpassword');
        }, Config.averageMsgTimeout);
    }

    togglePassword(value) {
        this.setState({ passwordShown: value });
    }

    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="wrapper-content bg-white">
                                <div class="section-scroll" id="section-apply">
                                    <div class="bg-light p-5">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div class="section-title mb60 text-center">
                                                    <h1>Login</h1>
                                                    <p>Login to access your account</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="offset-xl-4 col-xl-8 offset-md-2 col-md-12 offset-md-2 col-md-12 col-sm-12 col-12">
                                                <form class="" onSubmit={this.handleLoginClick.bind(this)}>
                                                    <fieldset>
                                                        <div class="row">
                                                            <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12 form-group ">
                                                                <label class="control-label" htmlFor="mobile-number">Mobile Number</label>
                                                                <div class="">
                                                                    <input id="input-mobile-number" name="input-mobile-number" type="text" placeholder="Mobile Number" class="form-control input-md" maxLength="15" onChange={this.handleChange.bind(this, "mobileno")} value={this.state.fields["mobileno"]} autoFocus />
                                                                    <span class="help-block"> </span> </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label class="control-label" htmlFor="password">Password</label>
                                                                <div class="">
                                                                    <input id="input-password" name="input-password" type={this.state.passwordShown ? "text" : "password"} placeholder="Password" class="form-control" maxLength="30" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]} />
                                                                </div>
                                                                <div style={{ float: "right", marginTop: "-46px", marginRight: "12px" }} >
                                                                    {this.state.passwordShown ? <i className="fa fa-eye-slash" onClick={this.togglePassword.bind(this, false)}></i> : <i className="fa fa-eye" onClick={this.togglePassword.bind(this, true)}></i>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12">
                                                                <div class="">
                                                                    <button id="input-login" name="input-login" class="btn btn-primary btn-block btn-cusm btn-sm btn-bg">Login</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row" onClick={this.gotoForgotPassword.bind(this)}>
                                                            <div class="form-group col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12">
                                                                <span class="btn-link" >Forgot Password?</span>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(Login);