import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../component/loadinganimation';
import { withAlert } from 'react-alert' // HOC to show the alert message
import EConfig from '../../data/enumconfig';
import './dashboard.scss';
import moment from "moment";
import 'jspdf-autotable'
import { jsPDF } from "jspdf";

let token = '';



class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            balance: 0.00,
            memberName: '',
            membershipNo: '',
            balanceAsOn: moment(new Date()).format("DD-MM-yyyy hh:mm A"),
            transactionList: [],
            pendingTransactionList: [],
            paymentHistory: [],
            fromDate: moment(new Date()).format("yyyy-MM-DD"),
            toDate: moment(new Date()).format("yyyy-MM-DD"),
            opBalance: 0,
            opDate: '-'
        }
    }

    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }

        if (!sHelpers.LoggedinInputValidation()) {
            this.props.alert.error("Session Expired! Please log in again.");
            this.setState({ loading: true });
            setTimeout(() => {
                this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }
        token = sHelpers.secureStorage.getItem('club.token');
        this.getDashboardDetail();

    }

    getDashboardDetail() {
        this.getClosingBalance();
        this.getTransactionStatement();
    }

    getClosingBalance() {
        this.setState({ loading: true });


        let postObj = {
            MembershipId: parseInt(sHelpers.secureStorage.getItem('club.membership.id')),
            MembershipNo: sHelpers.secureStorage.getItem("club.membership.no"),
            Phone: sHelpers.secureStorage.getItem("club.mobile.no"),
            DeviceId: 0,
            CrossPlatform: EConfig.crossPlatform.Web
        }

        fetch(Config.baseClubUrl + 'GetClosingBalance', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    this.setState({ balance: response.amount });
                }
                else {
                    this.setState({ balance: 0 });
                    this.props.alert.error(response.message);
                }

                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ balance: 0 });
                this.setState({ loading: false });
                this.props.alert.error(err);
            });
    }

    getTransactionStatement() {
        this.setState({ loading: true });
        let fDate = new Date(this.state.fromDate);
        let tDate = new Date(this.state.toDate);
        let postObj = {
            MembershipId: parseInt(sHelpers.secureStorage.getItem('club.membership.id')),
            MembershipNo: sHelpers.secureStorage.getItem("club.membership.no"),
            Phone: sHelpers.secureStorage.getItem("club.mobile.no"),
            DeviceId: 0,
            FromDate: parseInt(fDate.getFullYear() + '' + ("0" + (fDate.getMonth() + 1)).slice(-2) + '' + ("0" + fDate.getDate()).slice(-2)),
            ToDate: parseInt(tDate.getFullYear() + '' + ("0" + (tDate.getMonth() + 1)).slice(-2) + '' + ("0" + tDate.getDate()).slice(-2)),
            CrossPlatform: EConfig.crossPlatform.Web
        }
        //  console.log(postObj);
        fetch(Config.baseClubUrl + 'GetTransactionStatement', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                console.log(response);
                if (response.errorCode === 0) {
                    this.setState({ transactionList: response.transactionList, pendingTransactionList: response.pendingTransactionList, paymentHistory: response.paymentHistory, opBalance: response.opBalance, opDate: response.opDate });
                }
                else {
                    this.setState({ transactionList: [], pendingTransactionList: [], paymentHistory: [], opBalance: 0, opDate: 0 });
                    this.props.alert.error(response.message);
                }

                this.setState({ loading: false });
                sHelpers.mTop();
            })
            .catch(err => {
                this.setState({ balance: 0 });
                this.setState({ loading: false });
                this.props.alert.error(err);
            });
    }

    handleRedirectPaymentProcess() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.props.history.push('/paymentprocess', { outstandingBalance: this.state.balance });
        }, Config.minMsgTimeout);
    }

    handleChange(type, e) {
        // console.log(type);
        if (type === 1)
            this.setState({ fromDate: e.target.value });
        else
            this.setState({ toDate: e.target.value });
    }

    handleTransactionListDom() {
        return (
            <>
                <h4 className="rate-number">
                    Opening Balance:
                    {this.state.opBalance < 0 && <span className="balance-primary"> &#x20B9; {this.state.opBalance.toFixed(2)}</span>}
                    {this.state.opBalance > 0 && <span className="balance-secondary"> &#x20B9; {this.state.opBalance.toFixed(2)}</span>}
                    {this.state.opBalance === 0 && <span className="balance-default"> &#x20B9; {this.state.opBalance.toFixed(2)}</span>}
                </h4>
                <small>As on {this.state.opDate.toString().toString().substr(6, 2) + '-' + this.state.opDate.toString().toString().substr(4, 2) + '-' + this.state.opDate.toString().toString().substr(0, 4)}</small>
                <table className="table table-bordered" id="tbl-tranactionlist">
                    <thead>
                        <tr className="table-head">
                            <th scope="col">Date</th>
                            <th scope="col" className="text-right">Debit</th>
                            <th scope="col" className="text-right">Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.transactionList.map(tran =>
                            <tr>
                                <td>{tran.transactionDate.toString().toString().substr(6, 2) + '-' + tran.transactionDate.toString().toString().substr(4, 2) + '-' + tran.transactionDate.toString().toString().substr(0, 4)} <br /> {tran.description}</td>
                                <td className="text-right">{tran.debit > 0 ? tran.debit.toFixed(2) : ''}</td>
                                <td className="text-right">{tran.credit > 0 ? tran.credit.toFixed(2) : ''}</td>
                            </tr>
                        )
                        }
                    </tbody>
                </table>
            </>
        )
    }

    handlePendingTransactionDom() {
        return (
            <>
                <table className="table table-bordered">
                    <tbody>
                        {this.state.pendingTransactionList.map(tran =>
                            <tr>
                                <td><span className="small-title">Date </span><br />{tran.transactionDate}<br /><span className="small-title">Description</span> <br />{tran.description}</td>
                                <td className="text-right"><span className="small-title">Amount</span><br />₹{tran.transactionAmount}({tran.docType}) <br /> <span><a href={tran.viewUrl} target="_blank" rel="noreferrer" className="btn-link">View Receipt</a></span></td>
                            </tr>
                        )
                        }
                    </tbody>
                </table>
            </>
        )
    }

    handlePaymentHistoryDom() {
        return (
            <>
                <table className="table table-bordered">
                    <tbody>
                        {this.state.paymentHistory.map(tran =>
                            <tr>
                                <td><span className="small-title">Date</span> <br /> {tran.transactionDate} <br /> <span className="small-title">Bill Id</span><br /> {tran.billId}</td>
                                <td><span className="small-title">Amount</span><br />₹{tran.amount} <br /> <span className="small-title">Status</span> <br />
                                    {tran.paymentStatusId === EConfig.paymentStatus.Success && <span className="status-success status-txt">{tran.paymentStatus}</span>}
                                    {tran.paymentStatusId === EConfig.paymentStatus.Failure && <span className="status-failed status-txt">{tran.paymentStatus}</span>}
                                    {tran.paymentStatusId === EConfig.paymentStatus.Pending && <span className="status-pending status-txt">{tran.paymentStatus}</span>}
                                    {tran.paymentStatusId === EConfig.paymentStatus.Cancelled && <span className="status-failed status-txt">{tran.paymentStatus}</span>}
                                </td>
                            </tr>
                        )
                        }
                    </tbody>
                </table>
            </>
        )
    }

    handleExportToPdf() {
        this.setState({ loading: true });
        setTimeout(() => {
            let doc = new jsPDF('p', 'mm', 'a4');
            //let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

            doc.page = 1;
            doc.setFontSize(14);
            //let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(sHelpers.secureStorage.getItem('club.property.name')) * doc.internal.getFontSize() / 2);
            let str = sHelpers.secureStorage.getItem('club.property.name');
            // doc.text(sHelpers.secureStorage.getItem('club.property.name'), xOffset, 10);
            doc.text(str, pageWidth / 2, 10, 'center');
            // doc.centerAlignText(sHelpers.secureStorage.getItem('club.property.name'), { align: "center" }, 0, 10);
            // doc.text(30, 10, sHelpers.secureStorage.getItem('club.property.name'));
            // doc.text(sHelpers.secureStorage.getItem('club.property.name'), 10, 10);
            doc.setFontSize(10);
            var footer = function (data) {
                doc.setFontSize(9);
                doc.text(8, 290, 'Page ' + doc.page);
                doc.text(130, 290, sHelpers.getCurrentDateTime("Printing Time: "));
                doc.page++;
            }

            // doc.text("OpeningBalance: " + this.state.opBalance, 80, 12);
            doc.autoTable({
                html: '#tbl-tranactionlist',
                startY: 14,
                columnStyles: {
                    1: {
                        halign: 'right'
                    },
                    2: {
                        halign: 'right'
                    }
                },
                headerStyles: {
                    lineWidth: 0,
                    //lineColor: [0, 0, 0],
                    fontStyle: 'bold',
                    fontSize: 10,
                    halign: 'center'

                },
                addPageContent: footer,
            });
            this.setState({ loading: false });
            doc.save("transaction-list.pdf");
        }, Config.minMsgTimeout);

    }

    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                {/* <div className="section-space80 bg-gradient"> */}
                <div className="section-space80 pinside20 bg-primary-light">
                    <div className="container" style={{ marginTop: "-64px" }}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li><a href="/home">Home</a></li>
                                        <li className="active">Account</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="bg-white pinside10">
                                    <div className="row align-items-center">
                                        <div className="col-xl-5 col-lg-5 col-md-4 col-sm-4 col-4">
                                            <h1 className="page-title member-caption">{sHelpers.secureStorage.getItem("club.displayname")}</h1>
                                            <small>{sHelpers.secureStorage.getItem("club.membership.no")}</small>
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-8 col-sm-8 col-8">
                                            <div className="row">
                                                <div className="col-xl-9 col-lg-8 col-md-12 d-xl-block d-lg-block">
                                                    <div className="rate-block text-right">
                                                        <h2 className="rate-number">
                                                            {this.state.balance < 0 && <span className="balance-primary">₹ {this.state.balance.toFixed(2)}</span>}
                                                            {this.state.balance > 0 && <span className="balance-secondary">₹ {this.state.balance.toFixed(2)}</span>}
                                                            {this.state.balance === 0 && <span className="balance-default"> ₹ {this.state.balance.toFixed(2)}</span>}
                                                        </h2>
                                                        <small>Balance as on <br />{this.state.balanceAsOn}</small>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                                                    <button id="input-verify" name="input-verify" className="btn btn-primary btn-block btn-pay-now btn-cusm btn-sm btn-bg" onClick={this.handleRedirectPaymentProcess.bind(this)}>Pay Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sub-nav" id="sub-nav">
                                    <ul className="nav nav-tabs nav-justified" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="tab-1" data-toggle="tab" href="#trans-list" role="tab" aria-controls="service1" aria-selected="true">Transaction List</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="tab-2" data-toggle="tab" href="#pending-trans-list" role="tab" aria-controls="service2" aria-selected="false">Unadjusted Payments</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="tab-3" data-toggle="tab" href="#payment-history" role="tab" aria-controls="service3" aria-selected="false">Payment History</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="wrapper-content bg-white">
                                <div className="bank-account-tabs st-tabs">

                                    {/* <ul className="nav nav-tabs nav-justified" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="tab-1" data-toggle="tab" href="#trans-list" role="tab" aria-controls="service1" aria-selected="true">Transaction List</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="tab-2" data-toggle="tab" href="#service2" role="tab" aria-controls="service2" aria-selected="false">Pending Transaction</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="tab-3" data-toggle="tab" href="#service3" role="tab" aria-controls="service3" aria-selected="false">Payment History</a>
                                        </li>
                                    </ul> */}

                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane fade show active" id="trans-list">
                                            <div className="row">
                                                <div className="col-md-12">

                                                    <fieldset>
                                                        <div className="row">
                                                            <div className="form-group col-xl-4 col-lg-3 col-md-12 col-sm-12 col-12">
                                                                <label className="control-label" htmlFor="name">From Date</label>
                                                                <div className="">
                                                                    <input id="fdate" name="fdate" type="date" value={this.state.fromDate} onChange={this.handleChange.bind(this, 1)} className="form-control input-md" />
                                                                    <span className="help-block"> </span> </div>
                                                            </div>

                                                            <div className="form-group col-xl-4 col-lg-3 col-md-12 col-sm-12 col-12">
                                                                <label className=" control-label" htmlFor="email">To Date</label>
                                                                <div className="">
                                                                    <input id="tdate" name="tdate" type="date" value={this.state.toDate} onChange={this.handleChange.bind(this, 2)} className="form-control input-md" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12 mt30">
                                                                <div className="">
                                                                    <button className="btn btn-primary btn-block btn-cusm btn-sm btn-bg mr10 " onClick={this.getTransactionStatement.bind(this)}>Filter</button>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12  mt30">
                                                                <div className="">
                                                                    <button className="btn btn-primary btn-block btn-cusm btn-sm btn-bg" onClick={this.handleExportToPdf.bind(this)}>Export <i className="fa fa-file-pdf f12" aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>

                                                </div>
                                            </div>
                                            {this.handleTransactionListDom()}
                                            {/* <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-head">
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Debit</th>
                                                        <th scope="col">Credit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>18-01-20201 <br /> Opening Balance</td>
                                                        <td></td>
                                                        <td>₹1200.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>17-01-20201 <br /> Bill #0987</td>
                                                        <td>₹1580.00</td>
                                                        <td> </td>
                                                    </tr>
                                                </tbody>
                                            </table> */}
                                        </div>
                                        <div role="tabpanel" className="tab-pane fade" id="pending-trans-list">
                                            {this.handlePendingTransactionDom()}
                                        </div>
                                        <div role="tabpanel" className="tab-pane fade" id="payment-history">
                                            {this.handlePaymentHistoryDom()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(Dashboard);